import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { pageView } from './gtm.client.js'

type Props = {
	gtmTrackingId: string
}

export function GtmScript({ gtmTrackingId }: Props) {
	const location = useLocation()
	const [gtmReady, setGtmReady] = useState(false)

	useEffect(() => {
		const gtmScript = document.createElement('script')

		gtmScript.innerHTML = `
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${gtmTrackingId}');`

		document.head.appendChild(gtmScript)
		setGtmReady(!!window.dataLayer)

		return () => {
			document.head.removeChild(gtmScript)
		}
	}, [gtmTrackingId])

	useEffect(() => {
		if (!gtmReady || !window.dataLayer) return
		pageView(location.pathname)
	}, [location, gtmReady])

	return (
		<noscript>
			<iframe
				title="GTM"
				src={`https://www.googletagmanager.com/ns.html?id=${gtmTrackingId}`}
				height="0"
				width="0"
				style={{ display: 'none', visibility: 'hidden' }}
			/>
		</noscript>
	)
}
