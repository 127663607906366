import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'
import { type ReactNode } from 'react'
import {
	isRouteErrorResponse,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useRouteError,
	type LinksFunction,
} from 'react-router'

import { type Route } from './+types/root'
import styles from './tailwind.css?url'
import { Error } from '~/components/ui/error'
import { Footer } from '~/components/ui/footer'
import { Header } from '~/components/ui/header'
import { GtmScript } from '~/utils/gtm'
import { makeMetaTitle } from '~/utils/meta'
import { UrlParamsTracking } from '~/utils/tracking'

const googleFontsUrl =
	'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'

export const links: LinksFunction = () => [
	{ rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
	{
		rel: 'icon',
		type: 'image/png',
		sizes: '32x32',
		href: '/favicon-32x32.png',
	},
	{
		rel: 'icon',
		type: 'image/png',
		sizes: '16x16',
		href: '/favicon-16x16.png',
	},
	{ rel: 'manifest', href: '/site.webmanifest' },
	{ rel: 'preload', href: styles, as: 'style' },
	{ rel: 'stylesheet', href: styles, as: 'style' },
	{ rel: 'preload', href: googleFontsUrl, as: 'style' },
	{ rel: 'stylesheet', href: googleFontsUrl, as: 'style' },
]

export const meta = () => {
	return [
		{
			name: 'description',
			content:
				'Vatomat - víme jak na vatu. Vyřízení dotace na zateplení kompletně online do 30ti pracovních dní.',
		},
		{
			name: 'og:title',
			content: makeMetaTitle(),
		},
		{ title: makeMetaTitle() },
		{ name: 'og:site_name', content: 'Vatomat.cz' },
		{ name: 'og:url', content: 'https://www.vatomat.cz' },
		{
			name: 'og:description',
			content:
				'Vatomat - víme jak na vatu. Vyřízení dotace na zateplení kompletně online do 30ti pracovních dní.',
		},
		{ name: 'og:type', content: 'website' },
		{ name: 'og:image', content: '/og-share.jpg' },
	]
}

export function loader() {
	const gtmId = process.env.GTM_ID
	return { gtmId }
}

type DocumentProps = {
	gtmId?: string
	children: ReactNode
}

function Document({ gtmId, children }: DocumentProps) {
	return (
		<html lang="en" className="light scroll-smooth">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
			</head>
			<body>
				<UrlParamsTracking />
				{gtmId && <GtmScript gtmTrackingId={gtmId} />}
				<Analytics />
				<SpeedInsights />
				{children}
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	)
}

export const ErrorBoundary = () => {
	const error = useRouteError()
	const is404 = isRouteErrorResponse(error) && error.status === 404
	return (
		<Document>
			<div className="container mx-auto flex min-h-screen flex-col justify-between space-y-6 md:mt-4 md:space-y-12 lg:space-y-24">
				<Header />
				<Error
					className="flex-1"
					message="Ups..."
					description={is404 ? 'Tady nic není.' : 'Něco se pokazilo.'}
				/>
				<Footer />
			</div>
		</Document>
	)
}

function App({ loaderData }: Route.ComponentProps) {
	return (
		<Document gtmId={loaderData.gtmId}>
			<Outlet />
		</Document>
	)
}

export default App
