import { Link } from 'react-router'
import { cn } from '~/utils/cn'

type Props = {
	message: string
	description: string
	className?: string
}

export function Error({ message, description, className }: Props) {
	return (
		<div
			className={cn('hero rounded-lg', className)}
			style={{
				backgroundImage:
					'url(https://eu-central-1-shared-euc1-02.graphassets.com/cluk77jyt0m4k07w108ezfwe1/resize=fit:max,height:800,width:1200/auto_image/cm09jwa0niqsh07us804k5msk)',
			}}
		>
			<div className="hero-overlay bg-neutral/60 rounded-lg"></div>
			<div className="hero-content text-center">
				<div className="max-w-md">
					<h1 className="text-5xl font-bold text-white">{message}</h1>
					<p className="py-6 text-white">{description}</p>
					<Link to={'/'} className="btn btn-success">
						Zkusím to znovu
					</Link>
				</div>
			</div>
		</div>
	)
}
